import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Label, Popup, Table } from 'semantic-ui-react';
import { OrganizationUserExtended } from './types';

interface Props {
  user: OrganizationUserExtended;
}

export default function OrganizationUserRow({ user }: Props) {
  const { baseOrganization, currentOrganization, email, firstName, id, lastName } = user;

  return (
    <Table.Row key={id}>
      <Table.Cell collapsing={true}>
        {email ? (
          <Link to={`/users/${email}`}>
            {firstName} {lastName}
          </Link>
        ) : (
          `${firstName} ${lastName}`
        )}
      </Table.Cell>
      <Table.Cell collapsing={true}>{email}</Table.Cell>
      <Table.Cell collapsing={true}>
        <div key={currentOrganization?.roleId} style={{ margin: '2px' }}>
          <Label>{currentOrganization?.roleId}</Label>
          <br />
        </div>
      </Table.Cell>
      <Table.Cell collapsing={true}>
        {currentOrganization?.locations.map(({ locationName }) => (
          <div key={locationName}>{locationName}</div>
        ))}
      </Table.Cell>
      <Table.Cell collapsing={true}>
        {!currentOrganization?.isBaseOrganization && baseOrganization && (
          <Popup content={baseOrganization.name} trigger={<Icon name="check circle" />} />
        )}
      </Table.Cell>
    </Table.Row>
  );
}
