import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button, List, ListItem } from 'semantic-ui-react';
import styled from 'styled-components';
import { useRecentUsers } from './utils';

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const UserEmailItemContent = styled(ListItem)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 1.1rem;
  justify-content: space-between;
  margin: 0.2rem 0;
  min-width: 400px;
  padding: 0.2rem 0.2rem;
`;

function RecentUsers() {
  const { deleteRecentUser, recentUsers } = useRecentUsers();

  if (recentUsers.length === 0) return null;

  return (
    <Container>
      <List>
        <ListItem>
          <h3>Recently Viewed Users</h3>
        </ListItem>
        {recentUsers.map(email => (
          <ListItem key={email}>
            <UserEmailItemContent>
              <Link to={`/users/${email}`}>
                <Icon name="user" />
                {email}
              </Link>

              <div style={{ display: 'inline' }}>
                <Button
                  basic={true}
                  circular={true}
                  onClick={() => deleteRecentUser(email)}
                  icon={true}
                  size="tiny"
                  color="red"
                >
                  <Icon name="trash" />
                </Button>
              </div>
            </UserEmailItemContent>
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default RecentUsers;
