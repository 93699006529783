import React from 'react';
import { Header } from 'semantic-ui-react';

import MultifactorEnrollment from './MultifactorEnrollment';

export default function Mulifactor({ userId, mfaEnrollments, organizations }) {
  const baseOrg = organizations.find(org => org.isBaseOrganization);
  return (
    <div style={{ margin: '0 0 1em' }}>
      <Header as="h3">MFA</Header>
      {mfaEnrollments?.length ? (
        <p>
          User is enrolled in MFA with the following factors:
          <br />
          Note: To completely reset MFA you may need to allow this list to refresh after each
          deletion in order to delete factors not initally returned by Auth0.
        </p>
      ) : (
        <p>
          User is not enrolled in MFA
          {baseOrg?.mfa?.enabled
            ? `, but MFA is required for their base organization - ${baseOrg.name}`
            : ''}
        </p>
      )}
      {mfaEnrollments?.map(enrollment => (
        <MultifactorEnrollment key={enrollment.id} userId={userId} enrollment={enrollment} />
      ))}
    </div>
  );
}
