import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Confirm,
  Header,
  Icon,
  Label,
  Loader,
  Segment,
  Table,
} from 'semantic-ui-react';
import styled, { keyframes } from 'styled-components';
import AddGlobalReleaseToggle from './AddGlobalReleaseToggle';
import GLOBAL_RELEASE_TOGGLES from './queries/globalReleaseToggles.gql';
import TOGGLE_RELEASE_TOGGLE from './queries/toggleGlobalReleaseToggle.gql';
import DELETE_RELEASE_TOGGLE from './queries/deleteGlobalReleaseToggle.gql';
import { useToasts } from 'react-toast-notifications';

const GloballyEnabledContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

const GloballyEnabledLabel = styled.span`
  display: inline-block;
  margin-left: 1rem;
`;

const highlightAnimation = keyframes`
  0% { background: inherit }
  50% { background: #ffdb4d }
  100% { background: inherit }
`;

const HighlightRow = styled(Table.Row)`
  animation: ${highlightAnimation} 3s ease-in;
`;

function GlobalReleaseTogglesList() {
  const [addedReleaseToggleId, setAddedReleaseToggleId] = useState('');
  const { data, loading, refetch } = useQuery(GLOBAL_RELEASE_TOGGLES, {
    fetchPolicy: 'cache-and-network',
  });
  const releaseToggles = data?.globalReleaseToggles ?? [];

  useEffect(() => {
    if (addedReleaseToggleId) {
      const timeout = setTimeout(() => {
        setAddedReleaseToggleId('');
      }, 4000);

      return () => clearTimeout(timeout);
    }
  }, [addedReleaseToggleId, setAddedReleaseToggleId]);

  if (loading && !data) {
    return <Loader active={true} />;
  }

  return (
    <div>
      <AddGlobalReleaseToggle
        onAddReleaseToggle={async releaseToggle => {
          await refetch();
          if (releaseToggle) {
            setAddedReleaseToggleId(releaseToggle.id);
          }
        }}
      />

      <Table basic={true} compact="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={4}>Release Toggles</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Enabled Globally</Table.HeaderCell>
            <Table.HeaderCell>UUID</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {releaseToggles.map(releaseToggle => (
            <ReleaseToggleRow
              key={releaseToggle.id}
              highlight={addedReleaseToggleId === releaseToggle.id}
              refetch={refetch}
              releaseToggle={releaseToggle}
            />
          ))}
        </Table.Body>
      </Table>
      {releaseToggles.length === 0 && (
        <Segment placeholder>
          <Header icon>
            <Icon name="search" />
            We couldn&apos;t find any matching release toggles
          </Header>
        </Segment>
      )}
    </div>
  );
}

export default GlobalReleaseTogglesList;

function ReleaseToggleRow({ highlight, refetch, releaseToggle }) {
  const { addToast } = useToasts();
  const [toggleReleaseToggle] = useMutation(TOGGLE_RELEASE_TOGGLE);

  const handleToggle = useCallback(
    async id => {
      try {
        await toggleReleaseToggle({
          variables: {
            input: { id },
          },
        });
      } catch (e) {
        addToast(`Error toggling enabled flag: ${e.message}`, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    },
    [toggleReleaseToggle]
  );

  const Row = highlight ? HighlightRow : Table.Row;
  const { globallyEnabled, id, name } = releaseToggle;

  return (
    <Row>
      <Table.Cell collapsing={true}>{name}</Table.Cell>
      <Table.Cell collapsing={true}>
        <GloballyEnabledContainer>
          <Checkbox
            checked={globallyEnabled}
            onChange={async e => {
              await handleToggle(id);
            }}
            toggle={true}
          />
          <GloballyEnabledLabel>
            <Label color={globallyEnabled ? 'green' : 'grey'} horizontal={true}>
              {globallyEnabled ? 'Enabled' : 'Disabled'}
            </Label>
          </GloballyEnabledLabel>
        </GloballyEnabledContainer>
      </Table.Cell>
      <Table.Cell collapsing={true}>{id}</Table.Cell>
      <Table.Cell collapsing={true}>
        <ConfirmDeleteReleaseToggle id={id} name={name} refetch={refetch} />
      </Table.Cell>
    </Row>
  );
}

function ConfirmDeleteReleaseToggle({ id, name, refetch }) {
  const [open, setOpen] = useState(false);
  const { addToast } = useToasts();
  const [deleteGlobalReleaseToggle, { loading }] = useMutation(DELETE_RELEASE_TOGGLE);

  const handleConfirm = useCallback(async () => {
    try {
      await deleteGlobalReleaseToggle({
        variables: {
          input: {
            id,
          },
        },
      });
      await refetch();
    } catch (e) {
      addToast(`Error deleting release toggle: ${e.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [deleteGlobalReleaseToggle, id]);

  return (
    <>
      <Button negative={true} onClick={() => setOpen(true)}>
        Delete
      </Button>
      <Confirm
        confirmButton={
          <Button color="red" disabled={loading} loading={loading} negative={true}>
            Delete
          </Button>
        }
        content={`Are you sure you wish to delete release toggle ${name}?`}
        onCancel={() => setOpen(false)}
        onConfirm={handleConfirm}
        open={open}
        size="small"
      />
    </>
  );
}
