import { startCase } from 'lodash';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Checkbox, Form, Icon, Popup } from 'semantic-ui-react';
import { useLazyQuery } from '@apollo/client';

import GET_ORGANIZATION_QUERY from '../Organizations/queries/getOrganization.gql';

import { grey } from '../../lib/colors';

const Container = styled.div`
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0px 10px 0px;
  border: 1px solid ${grey};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const CheckboxContainer = styled(Form.Group)`
  padding-left: 0.5em;
`;

export default function UserOrganizationForm({
  roleOptions,
  organizationOptions,
  isBaseOrganization,
  onChange = () => {},
  onDelete = () => {},
}) {
  const [locations, setLocations] = useState([]);
  const [roleId, setRoleId] = useState();
  const [canAccessInProgress, setCanAccessInProgress] = useState(false);

  const [getOrganization, { loading: orgLoading, data: { organization = {} } = {} }] =
    useLazyQuery(GET_ORGANIZATION_QUERY);

  useEffect(() => {
    onChange({
      roleId,
      locations,
      organizationId: organization.id,
      isBaseOrganization,
      canAccessInProgress,
    });
  }, [roleId, locations, organization.id, isBaseOrganization, canAccessInProgress]);

  const getLocationOptions = (locations = []) => {
    return locations.map(({ id, locationName }) => ({
      key: id,
      text: locationName,
      value: id,
    }));
  };

  const handleOrgChange = (e, { value }) => {
    getOrganization({ variables: { id: value } });
  };

  const handleRoleChange = (e, { value }) => {
    setRoleId(value);
  };

  const handleLocationChange = (e, { value }) => {
    setLocations(value);
  };

  const handleCanAccessInProgress = (e, { checked }) => {
    setCanAccessInProgress(checked);
  };

  const handleDelete = () => {
    onDelete(organization);
  };

  // allows spaces & case insensitive search
  // eg: `Site manager` would match `role-siteManager`
  const handleRoleSearch = (options, query) => {
    return options.filter(opt => startCase(opt.text).toLowerCase().includes(query.toLowerCase()));
  };

  const locationOptions = getLocationOptions(organization.locations);
  const label = `${isBaseOrganization ? 'Base' : 'Additional'} Organization`;
  const title = organization.name || `${isBaseOrganization ? 'Base' : 'Additional'} Organization`;

  return (
    <Container shaded={isBaseOrganization ? 1 : 0}>
      <Header>
        <h4>{title}</h4>
        {!isBaseOrganization && (
          <Button
            loading={orgLoading}
            basic
            circular
            onClick={handleDelete}
            icon
            size="tiny"
            color="red"
          >
            <Icon name="trash" />
          </Button>
        )}
      </Header>
      <Form.Group widths="equal">
        <Form.Select
          fluid
          required
          search
          label={label + ' Name'}
          name="organizationId"
          options={organizationOptions}
          placeholder="Organization"
          onChange={handleOrgChange}
          loading={orgLoading}
          value={organization.id || ''}
        />
        <Form.Select
          fluid
          required
          search={handleRoleSearch}
          name="roleId"
          label="Role"
          options={roleOptions}
          placeholder="Role"
          onChange={handleRoleChange}
          value={roleId || ''}
        />
        <Form.Select
          fluid
          multiple
          search
          name="locations"
          label="Locations"
          disabled={orgLoading || !locationOptions.length}
          options={locationOptions}
          placeholder="All Locations"
          onChange={handleLocationChange}
          loading={orgLoading}
          value={locations || []}
        />
      </Form.Group>
      <CheckboxContainer>
        <Popup
          content="Allows the user to view data that has not been released from QC"
          trigger={
            <Checkbox
              label="Can access unreleased data"
              checked={canAccessInProgress}
              onChange={handleCanAccessInProgress}
            />
          }
        />
      </CheckboxContainer>
    </Container>
  );
}
