import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Checkbox, Popup } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';

import SET_CAN_RECEIVE_CDN from './queries/setCanReceiveCDN.gql';

export default function CanReceiveCDN({
  userId,
  organizationId,
  canReceiveCDN: canReceiveCDNPropState,
}) {
  const { addToast } = useToasts();
  const [updateCanReceiveCDN] = useMutation(SET_CAN_RECEIVE_CDN);
  const [canReceiveCDN, setCanReceiveCDN] = useState(canReceiveCDNPropState);

  const handleCanReceiveCDN = async () => {
    try {
      await updateCanReceiveCDN({
        variables: {
          input: {
            id: userId,
            canReceiveCDN: !canReceiveCDN,
            organizationId,
          },
        },
      });

      addToast('can receive critical damage notifications updated!', {
        appearance: 'success',
        autoDismiss: true,
      });
      setCanReceiveCDN(!canReceiveCDN);
    } catch (err) {
      addToast('can receive critical damage notifications update failed: ' + err.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <Popup
      content="WARNING: User must still choose to subscribe in their account. This does not subscribe them, it just allows them to subscribe themselves."
      trigger={
        <Checkbox
          label="Can receive critical damage notifications"
          checked={canReceiveCDN}
          onChange={() => handleCanReceiveCDN()}
        />
      }
    />
  );
}
