import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Popup, Confirm, Label, Icon } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import REMOVE_USER_MFA_ENROLLMENT from './queries/removeUserMFAEnrollment.gql';

export default function MulifactorEnrollment({ userId, enrollment }) {
  const { addToast } = useToasts();

  const [confirmOpen, setConfirmOpen] = useState(false);

  const [removeUserMFAEnrollment, { loading }] = useMutation(REMOVE_USER_MFA_ENROLLMENT, {
    refetchQueries: ['userByEmail'],
  });

  const handleRemoveEnrollment = async () => {
    try {
      await removeUserMFAEnrollment({
        variables: {
          input: {
            userId,
            enrollmentId: enrollment.id,
          },
        },
      });
      addToast(`${enrollment.type} has been removed`, { appearance: 'success', autoDismiss: true });
      setConfirmOpen(false);
    } catch (error) {
      addToast(`Error while removing ${enrollment.type}: ${error.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
      setConfirmOpen(false);
    }
  };

  return (
    <Label>
      <Popup
        content="Remove MFA Factor"
        trigger={<Icon link name="remove" onClick={() => setConfirmOpen(true)} loading={loading} />}
      />
      {enrollment.type}
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleRemoveEnrollment()}
      />
    </Label>
  );
}
