import React, { useCallback } from 'react';
import ReleaseToggles from '../ReleaseToggles';
import ADD_RELEASE_TOGGLE from './queries/addOrganizationReleaseToggle.gql';
import DELETE_RELEASE_TOGGLE from './queries/deleteOrganizationReleaseToggle.gql';
import { useMutation } from '@apollo/client';

function OrganizationReleaseToggles({ organization }) {
  const id = organization.id;
  const [addReleaseToggle] = useMutation(ADD_RELEASE_TOGGLE);
  const [deleteReleaseToggle] = useMutation(DELETE_RELEASE_TOGGLE);

  const onUpdate = useCallback(
    async ({ releaseToggle, type }) => {
      const mutate = type === 'add' ? addReleaseToggle : deleteReleaseToggle;
      await mutate({
        variables: {
          input: {
            id,
            releaseToggle,
          },
        },
      });
    },
    [addReleaseToggle, deleteReleaseToggle, id]
  );

  return <ReleaseToggles onUpdate={onUpdate} releaseToggles={organization.releaseToggles ?? []} />;
}

export default OrganizationReleaseToggles;
