import React, { useCallback, useMemo, useState } from 'react';
import { Grid, Dropdown, Button } from 'semantic-ui-react';
import {
  useAddOrganizationPermissionMutation,
  useGetOrganizationsQuery,
  UserByEmailQuery,
} from 'generated/graphql';

interface Props {
  user: UserByEmailQuery['userByEmail'];
}

function AddUserOrganization({ user }: Props) {
  const [organizationId, setOrganizationId] = useState<string>('');
  const { loading: loadingOrgs, data } = useGetOrganizationsQuery();
  const [addOrg, { loading: loadingAddOrg }] = useAddOrganizationPermissionMutation({
    refetchQueries: ['userByEmail'],
  });

  const handleAddOrg = useCallback(
    async (organizationId: string) => {
      if (!organizationId) return;
      await addOrg({
        variables: {
          input: {
            userId: user.id,
            organizationId,
          },
        },
      });
      setOrganizationId('');
    },
    [user.id]
  );

  const selectableOrgs = useMemo(() => {
    const orgs = (data?.organizations ?? [])
      .filter(o => !user.organizations?.map(o => o?.id).includes(o.id))
      .map(o => ({ key: o.id, value: o.id, text: o.name }));

    return [{ key: '--none--', value: '', text: '-- Select Organization --' }, ...orgs];
  }, [data?.organizations, user.organizations]);

  return (
    <div>
      <h4>Add organization permission</h4>
      <Grid>
        <Grid.Column floated="left" width={8}>
          <Dropdown
            closeOnChange={true}
            fluid={true}
            loading={loadingOrgs}
            onChange={(_, { value }) => {
              setOrganizationId(String(value));
            }}
            options={selectableOrgs}
            placeholder="-- Select Organization --"
            search={true}
            selection={true}
            value={organizationId}
          />
        </Grid.Column>
        <Grid.Column floated="right" width={8}>
          <Button
            onClick={() => handleAddOrg(organizationId)}
            loading={loadingAddOrg}
            basic={true}
            color="blue"
          >
            Add
          </Button>
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default AddUserOrganization;
