import React from 'react';
import { Route, Switch } from 'react-router-dom';
import GlobalReleaseTogglesList from './GlobalReleaseTogglesList';

function ReleaseManagementRoot() {
  return (
    <Switch>
      <Route exact={true} path="/release-management" component={GlobalReleaseTogglesList} />
    </Switch>
  );
}

export default ReleaseManagementRoot;
