import { Reducer, useCallback, useReducer } from 'react';
import { sortBy } from 'lodash';

type Direction = 'ascending' | 'descending';
type Sorter<T> = (column: string, records: T[], direction: Direction) => T[];

interface ChangeSortAction<T> {
  type: 'CHANGE_SORT';
  column: string;
  sorter?: Sorter<T>;
}

interface SetRecordsAction<T> {
  type: 'SET_RECORDS';
  records: T[];
}

interface AddRecordsAction<T> {
  type: 'ADD_RECORDS';
  records: T[];
  sort?: boolean;
  sorter?: Sorter<T>;
}

type ClientTableSortAction<T> = ChangeSortAction<T> | AddRecordsAction<T> | SetRecordsAction<T>;

interface ClientTableSortState<T> {
  column: string | null;
  direction: Direction;
  records: T[];
}

interface UseClientTableSortArgs<T> {
  records?: T[];
  sorter?: Sorter<T>;
}

export function useClientTableSort<T>({ records, sorter }: UseClientTableSortArgs<T> = {}) {
  const [state, dispatch] = useReducer<Reducer<ClientTableSortState<T>, ClientTableSortAction<T>>>(
    clientTableSortReducer,
    {
      column: null,
      direction: 'ascending',
      records: records ?? [],
    }
  );

  const sortColumn = useCallback((column: string) => {
    dispatch({ column, type: 'CHANGE_SORT', sorter });
  }, []);

  const setRecords = useCallback((records: T[]) => {
    dispatch({ type: 'SET_RECORDS', records });
  }, []);

  const addRecords = useCallback(
    (records: T[], sort?: boolean) => {
      dispatch({ type: 'ADD_RECORDS', records, sort, sorter });
    },
    [sorter]
  );

  const sorted = useCallback(
    (column: string) => (state.column === column ? state.direction : undefined),
    [state.column, state.direction]
  );

  return { addRecords, setRecords, sortColumn, sorted, state };
}

function clientTableSortReducer<T>(
  state: ClientTableSortState<T>,
  action: ClientTableSortAction<T>
): ClientTableSortState<T> {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        const direction = state.direction === 'ascending' ? 'descending' : 'ascending';
        return {
          ...state,
          records: action.sorter
            ? action.sorter(action.column, state.records, direction)
            : state.records.slice().reverse(),
          direction,
        };
      }

      return {
        column: action.column,
        records: action.sorter
          ? action.sorter(action.column, state.records, 'ascending')
          : defaultSorter(state.records, action.column),
        direction: 'ascending',
      };

    case 'SET_RECORDS':
      return {
        ...state,
        records: action.records,
      };

    case 'ADD_RECORDS':
      let newRecords = [...state.records, ...action.records];

      if (action.sort && state.column) {
        newRecords = action.sorter
          ? action.sorter(state.column, newRecords, state.direction)
          : defaultSorter(newRecords, state.column);
      }

      return {
        ...state,
        records: newRecords,
      };

    default:
      throw new Error();
  }
}

function defaultSorter<T>(records: T[], column: string) {
  return sortBy(records, [column]);
}
