import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Table } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import GET_ORGANIZATION_LOCATIONS from './queries/getOrganizationLocations.gql';

// only retrieve 10 location records at a time to reduce the load on assets
// service per request
const limit = 10;

function OrganizationLocationsList({ id }) {
  const [locations, setLocations] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(1);

  const [loadLocations, { error, loading }] = useLazyQuery(GET_ORGANIZATION_LOCATIONS, {
    onCompleted: data => {
      const newLocations = data?.organization.locations ?? [];
      setLocations(prev => [...prev, ...newLocations]);

      const newLoadMore = newLocations.length >= limit;
      setLoadMore(newLoadMore);

      if (newLoadMore) {
        setPage(prev => prev + 1);
      }
    },
  });

  const load = useCallback(() => {
    if (!loading) {
      const offset = page * limit - limit;
      loadLocations({ variables: { id, limit, offset } });
    }
  }, [id, loading, loadLocations, page]);

  useEffect(() => {
    load();
  }, []);

  return (
    <InfiniteScroll dataLength={locations.length} hasMore={loadMore} next={load}>
      <Table basic="very" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Slug</Table.HeaderCell>
            <Table.HeaderCell>Id</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {error && (
            <Table.Row>
              <Table.Cell collapsing={true} colSpan={3}>
                {error}
              </Table.Cell>
            </Table.Row>
          )}
          {locations.map(({ id, locationName, locationSlug }) => {
            return (
              <Table.Row key={id}>
                <Table.Cell collapsing={true}>{locationName}</Table.Cell>
                <Table.Cell collapsing={true}>{locationSlug}</Table.Cell>
                <Table.Cell collapsing={true}>{id}</Table.Cell>
              </Table.Row>
            );
          })}
          {loading && (
            <Table.Row>
              <Table.Cell collapsing={true} colSpan={3}>
                Loading...
              </Table.Cell>
            </Table.Row>
          )}
          {!loading && !loadMore && locations.length === 0 && (
            <Table.Row>
              <Table.Cell collapsing={true} colSpan={3}>
                This organization has no locations.
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </InfiniteScroll>
  );
}

export default OrganizationLocationsList;
