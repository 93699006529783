import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button, Confirm } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';

import ENABLE_USER from './queries/enableUser.gql';
import DISABLE_USER from './queries/disableUser.gql';
import DELETE_USER from './queries/deleteUser.gql';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export default function UserStatusActions({ user }) {
  const { addToast } = useToasts();
  const history = useHistory();

  const [enableUser, { loading: enableLoading, error: enableError }] = useMutation(ENABLE_USER, {
    refetchQueries: ['userByEmail'],
  });

  const [disableUser, { loading: disableLoading, error: disableError }] = useMutation(
    DISABLE_USER,
    { refetchQueries: ['userByEmail'] }
  );

  const [deleteUser, { data: userDeleted, loading: deleteLoading, error: deleteError }] =
    useMutation(DELETE_USER, { refetchQueries: ['userByEmail'] });

  const [confirmVisible, setConfirmVisible] = useState(false);

  const error = Boolean(enableError || disableError || deleteError);
  const loading = Boolean(enableLoading || disableLoading || deleteLoading);

  const showError = action => {
    addToast(`There was a problem ${action || 'updating'} the user`, {
      appearance: 'error',
      autoDismiss: true,
    });
  };

  const handleDisableUser = () => {
    try {
      disableUser({ variables: { id: user.id } });
    } catch (err) {
      showError();
    }
  };

  const handleEnableUser = () => {
    try {
      enableUser({ variables: { id: user.id } });
    } catch (err) {
      showError();
    }
  };

  const handleDeleteUser = () => {
    try {
      deleteUser({ variables: { id: user.id } });
    } catch (err) {
      showError('deleting');
    }
  };

  useEffect(() => {
    if (userDeleted) {
      history.push('/users');
    }
  }, [userDeleted]);

  return (
    <div>
      {user.enabled ? (
        <Button basic disabled={loading || error} loading={loading} onClick={handleDisableUser}>
          Disable User
        </Button>
      ) : (
        <ButtonContainer>
          <Button
            basic
            primary
            disabled={loading || error}
            loading={loading}
            onClick={handleEnableUser}
          >
            Enable User
          </Button>
          <Button
            basic
            negative
            disabled={loading || error}
            loading={loading}
            onClick={() => setConfirmVisible(true)}
          >
            Delete User
          </Button>
        </ButtonContainer>
      )}
      <Confirm
        open={confirmVisible}
        content="Are you sure you want to delete this user? This action is permanent and can not be undone."
        confirmButton="Permanently Delete User"
        onCancel={() => setConfirmVisible(false)}
        onConfirm={handleDeleteUser}
      />
    </div>
  );
}
