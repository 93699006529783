import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { get, uniqBy } from 'lodash';
import { useToasts } from 'react-toast-notifications';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Form, Icon, Loader, Message } from 'semantic-ui-react';

import UserOrganizationForm from './UserOrganizationForm';

import GET_ROLES_QUERY from './queries/getUserRoles.gql';
import CREATE_USER_MUTATION from '../Users/queries/createUser.gql';
import GET_ORGANIZATIONS_QUERY from '../Organizations/queries/getOrganizations.gql';
import getErrorMessage from '../../lib/getErrorMessage';

const requiredFields = [
  { name: 'Email', key: 'email' },
  { name: 'First Name', key: 'firstName' },
  { name: 'Last Name', key: 'lastName' },
];

const getFormErrors = formInput => {
  const formErrors = requiredFields
    .map(field => (get(formInput, field.key) ? null : field.name))
    .filter(Boolean);

  // check for base organization
  const baseOrg = formInput.organizations.filter(org => org.isBaseOrganization);
  if (!baseOrg) formErrors.push('Missing Base Organization');

  // check for orgId
  if (!formInput.organizations.every(org => org.organizationId)) {
    formErrors.push('Missing Organization Name(s)');
  }

  // check for roles
  if (!formInput.organizations.every(org => org.roleId)) {
    formErrors.push('Missing Organization Role(s)');
  }

  // check for duplicates
  if (uniqBy(formInput.organizations, 'organizationId').length !== formInput.organizations.length) {
    formErrors.push('Duplicate Organizations');
  }

  return formErrors;
};

const orgTemplate = {
  organizationId: undefined,
  roleId: undefined,
  locations: [],
  canAccessInProgress: false,
};

const baseOrgTemplate = {
  ...orgTemplate,
  isBaseOrganization: true,
};

export default function CreateUser({ match }) {
  const history = useHistory();
  const { addToast } = useToasts();
  const [formErrors, setMissingFields] = useState([]);
  const [formInput, setFormInput] = useState({
    email: match.params?.email?.toLowerCase(),
    groups: ['horizon'],
    organizations: [{ ...baseOrgTemplate }],
  });
  const { data: { organizations } = {}, loading: customersLoading } =
    useQuery(GET_ORGANIZATIONS_QUERY);
  const { data: { userRoles } = {}, loading: rolesLoading } = useQuery(GET_ROLES_QUERY);

  const [
    createUser,
    {
      client,
      loading: createUserLoading,
      error: createUserError,
      data: { createUser: createdUser } = {},
    },
  ] = useMutation(CREATE_USER_MUTATION, {
    onCompleted: () => {
      client.resetStore();
    },
  });

  function redirectToUser() {
    history.push(`/users/${formInput.email}`);
  }

  useEffect(() => {
    if (createdUser) {
      redirectToUser();
    }
  }, [createdUser]);

  const handleAddOrganization = e => {
    e.preventDefault();
    setFormInput({
      ...formInput,
      organizations: [...formInput.organizations, { ...orgTemplate }],
    });
  };

  const handleOrgChange = idx => organization => {
    const userOrgs = [...formInput.organizations];
    userOrgs[idx] = organization;
    setFormInput({
      ...formInput,
      organizations: userOrgs,
    });
  };

  const handleOrgDelete = idx => () => {
    const userOrgs = [...formInput.organizations];
    userOrgs.splice(idx, 1);
    setFormInput({
      ...formInput,
      organizations: userOrgs,
    });
  };

  const handleChange = (e, { name, value, checked }) => {
    setFormInput({
      ...formInput,
      [name]: name === 'email' ? value.toLowerCase() : value || checked,
    });
  };

  const handleSubmit = async e => {
    const missing = getFormErrors(formInput);
    setMissingFields(missing);
    if (missing.length) return;

    try {
      await createUser({ variables: { user: formInput } });
    } catch (err) {
      addToast('There was a problem creating the user: ' + getErrorMessage(err), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  if (customersLoading || rolesLoading) return <Loader active />;

  const organizationOptions = organizations.map(cus => ({
    key: cus.slug,
    text: cus.name,
    value: cus.id,
  }));

  const roleOptions = userRoles.map(({ name, isDeprecated }) => ({
    key: name,
    value: name,
    text: isDeprecated ? `[DEPRECATED] ${name}` : name,
  }));

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <h1>Create user account</h1>
      <Form
        loading={false}
        onSubmit={handleSubmit}
        error={Boolean(createUserError) || Boolean(formErrors.length)}
      >
        <Form.Group widths="equal">
          <Form.Input
            fluid
            required
            type="email"
            name="email"
            label="E-mail address"
            onChange={handleChange}
            placeholder="E-mail address"
            value={formInput.email || ''}
          />
          <Form.Input
            fluid
            required
            name="firstName"
            label="First name"
            placeholder="First name"
            onChange={handleChange}
            value={formInput.firstName || ''}
          />
          <Form.Input
            fluid
            required
            name="lastName"
            label="Last name"
            placeholder="Last name"
            onChange={handleChange}
            value={formInput.lastName || ''}
          />
        </Form.Group>
        {formInput.organizations.map((userOrg, idx) => (
          <UserOrganizationForm
            isBaseOrganization={idx === 0}
            roleOptions={roleOptions}
            organizationOptions={organizationOptions}
            key={userOrg.id || idx}
            onChange={handleOrgChange(idx)}
            onDelete={handleOrgDelete(idx)}
          />
        ))}
        <Form.Group>
          <Button onClick={handleAddOrganization}>
            <Icon name="plus" /> Add Organization
          </Button>
        </Form.Group>

        <Button
          basic
          positive
          loading={createUserLoading}
          disabled={createUserLoading}
          type="submit"
        >
          Create
        </Button>
        <Message
          error
          header="Error Creating User"
          content={
            createUserError ? (
              createUserError.message
            ) : (
              <ul>
                {formErrors.map(field => (
                  <li key={field}>{field}</li>
                ))}
              </ul>
            )
          }
        />
      </Form>
    </div>
  );
}
