import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Loader } from 'semantic-ui-react';

import UserMetadata from './UserMetadata';
import UserSetEmail from './UserSetEmail';
import UserSetFirstLastName from './UserSetFirstLastName';
import SetUserPassword from './SetUserPassword';
import UserOrganizations from './UserOrganizations';
import UserStatusActions from './UserStatusActions';
import UserGroupsDropdown from './UserGroupsDropdown';
import AddUserOrganization from './AddUserOrganization';
import getErrorMessage from '../../lib/getErrorMessage';
import SendPasswordResetEmail from './SendPasswordResetEmail';

import GET_USER_BY_EMAIL from './queries/getUserByEmail.gql';
import SET_FEATURES from './queries/setUserFeatures.gql';
import Features from '../Features';
import CanUseDevTools from './CanUseDevTools';
import Mulifactor from './Multifactor';
import UserReleaseToggles from './UserReleaseToggles';
import { useRecentUsers } from './utils';

const UserItem = styled.div`
  margin: 10px 0px;
`;

const SplitContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const ErrorContainer = styled.div`
  padding: 100px 0px 100px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-weight: 300;
  font-size: 2rem;
  text-transform: lowercase;
  color: #ababab;
`;

export default function UserDetail({ match }) {
  const email = match.params.email;
  const { data, error, loading, refetch } = useQuery(GET_USER_BY_EMAIL, {
    skip: !email,
    variables: { email },
  });

  useRecentUsers(email);

  if (loading) return <Loader active />;

  if (error) {
    return (
      <ErrorContainer>
        <p>{getErrorMessage(error)}</p>
        <Button primary as={Link} to={`/users/create/${email}`}>
          Create User
        </Button>
      </ErrorContainer>
    );
  }

  const userByEmail = data?.userByEmail;

  if (!userByEmail) {
    return <ErrorContainer>User not found.</ErrorContainer>;
  }

  return (
    <div>
      <UserItem>
        <SplitContainer>
          <UserMetadata user={userByEmail} />
          <UserStatusActions user={userByEmail} />
        </SplitContainer>
      </UserItem>
      <UserItem>
        <UserSetEmail user={userByEmail} />
        <UserSetFirstLastName user={userByEmail} refetch={refetch} />
        <UserGroupsDropdown id={userByEmail.id} groups={userByEmail.groups ?? []} />
        <Features id={userByEmail.id} features={userByEmail.features} query={SET_FEATURES} />
        <UserReleaseToggles user={userByEmail} />
        <CanUseDevTools userId={userByEmail.id} canUseDevTools={userByEmail.canUseDevTools} />
      </UserItem>
      <UserItem>
        <Mulifactor
          mfaEnrollments={userByEmail.auth0?.mfaEnrollments}
          organizations={userByEmail.organizations}
          userId={userByEmail.id}
        />
      </UserItem>
      <UserItem>
        <h3>Organizations</h3>
        <AddUserOrganization user={userByEmail} />
        <UserOrganizations user={userByEmail} organizations={userByEmail.organizations} />
      </UserItem>
      <UserItem>
        <SendPasswordResetEmail id={userByEmail.id} />
        <SetUserPassword id={userByEmail.id} />
      </UserItem>
    </div>
  );
}
