import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Logout from './Logout';
import NotFound from './NotFound';
import PrivateRoute from './PrivateRoute';
import UserDetail from './Users/UserDetail';
import UserSearchBar from './Users/UserSearchBar';
import CreateUserForm from './Users/CreateUserForm';
import OrganizationsList from './Organizations/OrganizationsList';
import OrganizationDetail from './Organizations/OrganizationDetail';
import CreateOrganizationForm from './Organizations/CreateOrganizationForm';
import ReleaseManagementRoot from './ReleaseManagement';

const Routes = () => (
  <Switch>
    <Route exact={true} path="/" component={UserSearchBar} />
    <Route exact={true} path="/logout" component={Logout} />
    <PrivateRoute exact={true} path="/users" component={UserSearchBar} />
    <PrivateRoute exact={true} path="/users/create" component={CreateUserForm} />
    <PrivateRoute path="/users/create/:email" component={CreateUserForm} />
    <PrivateRoute path="/users/:email" component={UserDetail} />
    <PrivateRoute path="/organizations" exact={true} component={OrganizationsList} />
    <PrivateRoute exact={true} path="/organizations/create" component={CreateOrganizationForm} />
    <PrivateRoute path="/organizations/:id" component={OrganizationDetail} />
    <PrivateRoute path="/release-management" component={ReleaseManagementRoot} />
    <PrivateRoute component={NotFound} />
  </Switch>
);

export default Routes;
