import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Grid, Input, Button } from 'semantic-ui-react';

import SET_USER_EMAIL from './queries/setUserEmail.gql';
import { useHistory } from 'react-router-dom';

export default function UserSetEmail({ user }) {
  const history = useHistory();
  const [newEmail, setNewEmail] = useState('');
  const [setUserEmailRemote, { loading }] = useMutation(SET_USER_EMAIL);

  const handleChange = (_e, { value }) => {
    setNewEmail(value.toLowerCase());
  };

  async function handleSubmit() {
    await setUserEmailRemote({
      variables: {
        input: {
          id: user.id,
          email: newEmail,
        },
      },
    });

    // redirect to page with new email
    history.push(`/users/${newEmail}`);
  }

  return (
    <div style={{ marginTop: '5px' }}>
      <h4>Change User Email</h4>
      <Grid>
        <Grid.Column width={12}>
          <Input
            fluid
            disabled={loading}
            loading={loading}
            onChange={handleChange}
            value={newEmail}
            placeholder={user.email}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <Button disabled={loading || !newEmail} onClick={handleSubmit} fluid>
            Change Email
          </Button>
        </Grid.Column>
      </Grid>
    </div>
  );
}
