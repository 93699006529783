import { startCase } from 'lodash';
import styled from 'styled-components';
import React, { useState } from 'react';
import GET_ORGANIZATIONS from './queries/getOrganizations.gql';

import { useQuery } from '@apollo/client';
import { useHistory, Link } from 'react-router-dom';
import { Header, Icon, Input, Loader, Segment, Table, Button } from 'semantic-ui-react';

const cleanString = (str = '') => startCase(str.trim()).toLowerCase();
const fuzzyIncludes = (source = '', query = '') =>
  cleanString(source || '').includes(cleanString(query || ''));

const ButtonContainer = styled.div`
  display: flex;
  min-width: 220px;
  margin: 0.5rem 0;
  align-items: center;
  justify-content: flex-end;
`;

const TableComponentContainer = styled.div`
  height: 36px;
  display: flex;
  flex-direction: row;
`;

export default function OrganizationList() {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState('');

  const { loading, data } = useQuery(GET_ORGANIZATIONS, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading && !data) return <Loader active />;

  const organizations = data?.organizations ?? [];

  const customerOptions = searchQuery
    ? organizations.filter(
        cus =>
          fuzzyIncludes(cus.name, searchQuery) ||
          fuzzyIncludes(cus.slug, searchQuery) ||
          fuzzyIncludes(cus.id, searchQuery) ||
          fuzzyIncludes(cus.sfId, searchQuery)
      )
    : organizations;

  const handleSearchChange = (e, { value }) => {
    setSearchQuery(value);
  };

  const handleClearQuery = () => {
    setSearchQuery('');
  };

  return (
    <div>
      <TableComponentContainer>
        <Input
          icon="search"
          iconPosition="left"
          value={searchQuery}
          placeholder="Search..."
          style={{ width: '100%' }}
          onChange={handleSearchChange}
        />
        <ButtonContainer>
          <Button primary as={Link} to="/organizations/create">
            <Icon name="add" />
            Create Organization
          </Button>
        </ButtonContainer>
      </TableComponentContainer>
      <Table basic compact="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Slug</Table.HeaderCell>
            <Table.HeaderCell>UUID</Table.HeaderCell>
            <Table.HeaderCell>SFID</Table.HeaderCell>
            <Table.HeaderCell>MFA</Table.HeaderCell>
            <Table.HeaderCell>Details</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {customerOptions.map(organization => (
            <Table.Row key={organization.id}>
              <Table.Cell collapsing>{organization.name}</Table.Cell>
              <Table.Cell collapsing>{organization.slug}</Table.Cell>
              <Table.Cell collapsing>{organization.id}</Table.Cell>
              <Table.Cell collapsing>{organization.sfId}</Table.Cell>
              <Table.Cell collapsing>{organization.mfa?.enabled && 'yes'}</Table.Cell>
              <Table.Cell collapsing>
                <Button compact onClick={() => history.push(`/organizations/${organization.id}`)}>
                  Details
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {!customerOptions.length && (
        <Segment placeholder>
          <Header icon>
            <Icon name="search" />
            We couldn&apos;t find any matching organizations
          </Header>
          <Button primary onClick={handleClearQuery}>
            Clear Query
          </Button>
        </Segment>
      )}
    </div>
  );
}
