import React, { useState, useMemo } from 'react';
import { useToasts } from 'react-toast-notifications';
import DatePicker from 'react-datepicker';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

import getErrorMessage from '../../lib/getErrorMessage';
import SET_USER_ORG_EXPIRATION from '../Users/queries/setUserOrgExpiration.gql';
import { grey } from '../../lib/colors';

const StyledDatePicker = styled(DatePicker)`
  border: 1px solid ${grey};
  border-radius: 5px;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  height: 38px;
  margin: 0px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 3.167px;
  padding-bottom: 3.167px;
`;

export default function UserOrgExpirationSelector({
  organizationId,
  userId,
  expires: expiresInput,
  disabled,
}) {
  const [expiration, setExpiration] = useState(expiresInput * 1000);

  const { addToast } = useToasts();

  const [setExpirationRemote] = useMutation(SET_USER_ORG_EXPIRATION, {
    refetchQueries: ['userByEmail'],
  });

  const minDate = useMemo(() => new Date(), []);

  const handleChange = async date => {
    const epochTime = new Date(date).getTime() / 1000;

    setExpiration(date);

    try {
      await setExpirationRemote({
        variables: {
          input: {
            id: userId,
            expires: epochTime,
            organizationId,
          },
        },
      });

      addToast('Expiration date updated!', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      console.error(err);
      addToast('There was a problem updating the expiration time: ' + getErrorMessage(err), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <div>
      <b>Expires</b>
      <StyledDatePicker selected={expiration} onChange={handleChange} minDate={minDate} />
    </div>
  );
}
