import React from 'react';
import { Button } from 'semantic-ui-react';
import { useAuth0 } from '../lib/auth0';

const AuthButtons = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <div>
      {!isAuthenticated && (
        <Button primary onClick={() => loginWithRedirect({})}>
          Log in
        </Button>
      )}
      {isAuthenticated && (
        <Button onClick={() => logout({ returnTo: window.location.origin })}>Log out</Button>
      )}
    </div>
  );
};

export default AuthButtons;
