import React from 'react';
import SET_USER_NIAA_ROLE from '../queries/setUserOrgNiaaRole.gql';
import GET_USER_NIAA_ROLES from '../queries/getUserNiaaRoles.gql';
import SharedUserRoleDropdown, {
  SharedUserRoleDropdownProps,
  UserRole,
} from './SharedUserRoleDropdown';

interface Props extends Pick<SharedUserRoleDropdownProps, 'organizationId' | 'roleId' | 'userId'> {}

export default function UserNiaaRoleDropdown(props: Props) {
  return (
    <SharedUserRoleDropdown
      {...props}
      getUserRoles={data => {
        const userNiaaRoles: UserRole[] = data?.userNiaaRoles ?? [];
        return [...userNiaaRoles].sort((a, b) => a.name.localeCompare(b.name));
      }}
      getUserRolesDocument={GET_USER_NIAA_ROLES}
      setUserRoleDocument={SET_USER_NIAA_ROLE}
      title="NIAA Role"
    />
  );
}
