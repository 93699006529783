import React, { useState } from 'react';
import { Dropdown, Popup, Checkbox } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';

import getErrorMessage from '../../lib/getErrorMessage';
import SET_USER_LOCATIONS from './queries/setUserLocations.gql';
import GET_ORGANIZATION_BY_ID from '../Organizations/queries/getOrganizationLocations.gql';

const DropdownContainer = styled.div`
  flex-grow: 1;
`;

const UserLocationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ToggleContainer = styled.div`
  align-self: center;
`;

export default function UserLocationsDropdown({ locations, organizationId, userId }) {
  const { addToast } = useToasts();
  const locationsAreAssigned = locations.length > 0;

  const [userLocations, setUserLocations] = useState(locations.map(({ id }) => id));
  // populate locations dropdown by default only if at least one location is selected
  const [showLocationsChecked, setShowLocationsChecked] = useState(locationsAreAssigned);

  const { loading: loadingLocations, data: { organization } = {} } = useQuery(
    GET_ORGANIZATION_BY_ID,
    {
      variables: { id: organizationId },
      // We only want to query for the locations if the dropdown is enabled
      skip: !showLocationsChecked,
    }
  );

  const [setUserLocationsRemote, { loading: setUserLocationsLoading }] = useMutation(
    SET_USER_LOCATIONS,
    {
      refetchQueries: [
        'userByEmail',
        { query: GET_ORGANIZATION_BY_ID, variables: { id: organizationId } },
      ],
    }
  );

  const handleShowLocationsChecked = (e, { checked }) => {
    setShowLocationsChecked(checked);
  };

  const handleChange = async (e, { value }) => {
    setUserLocations(value);

    try {
      await setUserLocationsRemote({
        variables: {
          input: {
            id: userId,
            locationIds: value,
            organizationId: organizationId,
          },
        },
      });

      addToast('Locations updated!', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      console.error(err);
      addToast('There was a problem updating locations: ' + getErrorMessage(err), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const defaultOptions = organization
    ? organization.locations.map(({ id, locationName }) => {
        return { key: id, value: id, text: locationName };
      })
    : [];

  return (
    <UserLocationsContainer>
      <ToggleContainer>
        <Popup
          content="Show available locations for organization"
          trigger={
            <div>
              <div>Show</div>
              <Checkbox
                checked={showLocationsChecked}
                onChange={handleShowLocationsChecked}
                toggle
              />
            </div>
          }
        />
      </ToggleContainer>
      <DropdownContainer>
        <b>Locations</b>
        <Dropdown
          fluid
          multiple
          selection
          search
          value={userLocations}
          onChange={handleChange}
          placeholder="All Locations"
          options={defaultOptions}
          loading={loadingLocations || setUserLocationsLoading}
          disabled={!showLocationsChecked}
        />
      </DropdownContainer>
    </UserLocationsContainer>
  );
}
