import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button, List, ListItem } from 'semantic-ui-react';
import styled from 'styled-components';
import { useRecentOrgs } from './utils';

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const OrgItemContent = styled(ListItem)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 1.1rem;
  justify-content: space-between;
  margin: 0.2rem 0;
  min-width: 400px;
  padding: 0.2rem 0.2rem;
`;

function RecentOrgs() {
  const { deleteRecentOrg, recentOrgs } = useRecentOrgs();

  if (recentOrgs.length === 0) return null;

  return (
    <Container>
      <List>
        <ListItem>
          <h3>Recently Viewed Customers</h3>
        </ListItem>
        {recentOrgs.map(({ id, name }) => (
          <ListItem key={id}>
            <OrgItemContent>
              <Link to={`/organizations/${id}`}>
                <Icon name="users" />
                {name}
              </Link>

              <div style={{ display: 'inline' }}>
                <Button
                  basic={true}
                  circular={true}
                  onClick={() => deleteRecentOrg(id)}
                  icon={true}
                  size="tiny"
                  color="red"
                >
                  <Icon name="trash" />
                </Button>
              </div>
            </OrgItemContent>
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default RecentOrgs;
