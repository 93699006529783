import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, Popup } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import getErrorMessage from '../../lib/getErrorMessage';

export default function ResetUserPassword({ id }) {
  const { addToast } = useToasts();

  const SEND_PASSWORD_RESET_EMAIL = gql`
    mutation sendPasswordResetEmail($id: ID!) {
      sendPasswordResetEmail(id: $id)
    }
  `;

  const [sendPasswordResetEmail, { loading }] = useMutation(SEND_PASSWORD_RESET_EMAIL);

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail({ variables: { id } });
      addToast('Password reset succeeded', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      addToast('Password reset failed: ' + getErrorMessage(err), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <Popup
      content="Reset the users current password and sends them a password reset email with instructions on how to reset their password"
      trigger={
        <Button compact basic negative loading={loading} onClick={handlePasswordReset}>
          Send Password Reset Email
        </Button>
      }
    />
  );
}
