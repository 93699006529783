import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Grid, Input, Button } from 'semantic-ui-react';

import SET_USER_FIRST_LAST_NAME from './queries/setUserFirstLastName.gql';

export default function UserSetFirstLastName({ user, refetch }) {
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [setUserFirstLastName, { loading }] = useMutation(SET_USER_FIRST_LAST_NAME);

  async function handleSubmit() {
    await setUserFirstLastName({
      variables: {
        input: {
          id: user.id,
          firstName: newFirstName,
          lastName: newLastName,
        },
      },
      onCompleted() {
        setNewFirstName('');
        setNewLastName('');
        refetch();
      },
    });
  }

  return (
    <div style={{ marginTop: '5px' }}>
      <h4>Change Name</h4>
      <Grid>
        <Grid.Column width={6}>
          <Input
            fluid
            disabled={loading}
            loading={loading}
            onChange={(e, { value }) => {
              setNewFirstName(value);
            }}
            value={newFirstName}
            placeholder={user.firstName}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <Input
            fluid
            disabled={loading}
            loading={loading}
            onChange={(e, { value }) => {
              setNewLastName(value);
            }}
            value={newLastName}
            placeholder={user.lastName}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <Button disabled={loading || !newFirstName || !newLastName} onClick={handleSubmit} fluid>
            Change Name
          </Button>
        </Grid.Column>
      </Grid>
    </div>
  );
}
