import React from 'react';
import { Header, Segment, Icon } from 'semantic-ui-react';

import AuthButtons from './AuthButtons';

const Login = () => {
  return (
    <Segment placeholder>
      <Header icon>
        <Icon name="users" />
        Welcome to the SkySpecs Account Manager
      </Header>
      <AuthButtons />
    </Segment>
  );
};

export default Login;
