import { useCallback, useEffect, useState } from 'react';
import { getItem, removeItem, setItem } from 'lib/storage';

export function useRecentUsers(email?: string | null) {
  const [recentUsers, setRecentUsers] = useState<string[]>(getRecentUsers());

  const deleteRecentUser = useCallback((deleteEmail: string) => {
    const newUsers = removeRecentUser(deleteEmail);
    setRecentUsers(newUsers);
  }, []);

  const add = useCallback((addEmail: string) => {
    const newUsers = addRecentUser(addEmail);
    setRecentUsers(newUsers);
  }, []);

  useEffect(() => {
    if (email) {
      add(email);
    }
  }, [email]);

  return { addRecentUser: add, deleteRecentUser, recentUsers };
}

function getRecentUsers(): string[] {
  return getItem<string[]>('recent-users') ?? [];
}

function addRecentUser(email: string): string[] {
  const recentUsers = getRecentUsers();
  const newUsers = [...new Set([email, ...recentUsers])].filter(Boolean).slice(0, 10);

  setItem('recent-users', newUsers);

  return newUsers;
}

function removeRecentUser(email: string): string[] {
  const recentUsers = getRecentUsers();
  const newUsers = recentUsers.filter(anEmail => anEmail !== email);

  if (newUsers.length === 0) {
    removeItem('recent-users');
  } else {
    setItem('recent-users', newUsers);
  }

  return newUsers;
}
