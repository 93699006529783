import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';

const Password = styled.pre`
  font-weight: bold;
  cursor: pointer;
`;

const PasswordContent = ({ password, popupContent, onClick }) => (
  <span>
    Password set to:
    <Popup
      on="hover"
      content={popupContent}
      trigger={<Password onClick={onClick}>{password}</Password>}
    />
    This password will not be shown again.
  </span>
);

export const usePasswordToast = ({ onDismiss = () => {} } = {}) => {
  const { addToast, updateToast } = useToasts();
  const [copied, setCopied] = useState(false);
  const [password, setPassword] = useState(null);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(password);
    setCopied(true);
  }, [password]);

  const handleDismissed = useCallback((...args) => {
    setCopied(false);
    setPassword(null);
    return onDismiss(...args);
  });

  const addPasswordToast = pass => {
    setPassword(pass);
  };

  useEffect(() => {
    if (password) {
      addToast(
        <PasswordContent popupContent="Click to Copy" onClick={handleCopy} password={password} />,
        {
          appearance: 'success',
          id: 'password-success-toast',
          onDismiss: handleDismissed,
        }
      );
    }
  }, [password]);

  useEffect(() => {
    if (copied) {
      updateToast('password-success-toast', {
        content: (
          <PasswordContent
            popupContent="Copied to clipboard!"
            onClick={handleCopy}
            password={password}
          />
        ),
      });
    }
  }, [copied]);

  return { addPasswordToast };
};
