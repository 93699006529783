import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Checkbox, Popup } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';

import SET_CAN_USE_DEV_TOOLS from './queries/setCanUseDevTools.gql';

export default function CanUseDevTools({ userId, canUseDevTools: canUseDevToolsInit }) {
  const { addToast } = useToasts();
  const [remoteSetCanUseDevTools] = useMutation(SET_CAN_USE_DEV_TOOLS);
  const [canUseDevTools, setCanUseDevTools] = useState(canUseDevToolsInit);

  const handleCanUseDevToolsChange = async () => {
    try {
      await remoteSetCanUseDevTools({
        variables: {
          input: {
            id: userId,
            canUseDevTools: !canUseDevTools,
          },
        },
      });

      addToast('dev tools permission updated!', { appearance: 'success', autoDismiss: true });
      setCanUseDevTools(!canUseDevTools);
    } catch (err) {
      addToast('dev tools permission update failed: ' + err.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <Popup
      content="Allows the user to log in through the auth0 dev tools client"
      trigger={
        <Checkbox
          label="Can use dev tools"
          checked={canUseDevTools}
          onChange={() => handleCanUseDevToolsChange()}
        />
      }
    />
  );
}
