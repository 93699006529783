import React from 'react';
import styled from 'styled-components';

export const FOOTER_HEIGHT = '200px';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: ${FOOTER_HEIGHT};
`;

export default function Footer() {
  return (
    <Container>
      <img width="200px" src="https://horizon.skyspecs.com/logos/skyspecs/skyspecs-black.png" />
    </Container>
  );
}
