import React, { useState, useCallback } from 'react';
import { uniqBy } from 'lodash';
import { Dropdown, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import getErrorMessage from '../lib/getErrorMessage';
import { difference } from '../lib/utils';

const Container = styled.div`
  margin: 1.5em 0 1.5em 0;
`;

const Note = styled.div`
  font-style: italic;
  margin: 0.3em 0 0 0;
`;

const Code = styled.span`
  background: #efefef;
  display: inline-block;
  font-family: monospace;
  font-size: 0.9em;
  font-style: normal;
  margin: 0 0.5em;
`;

// interface OnUpdateArgs {
//   type: "add" | "delete";
//   releaseToggle: string;
// }
//
// interface ReleaseTogglesProps {
//   onUpdate: (args: OnUpdateArgs) => Promise<unknown>;
//   releaseToggles: string[];
// }
function ReleaseToggles({ onUpdate, releaseToggles: initialReleaseToggles }) {
  const { addToast } = useToasts();
  const [currentReleaseToggles, setCurrentReleaseToggles] = useState(initialReleaseToggles);

  const defaultOptions = uniqBy(
    initialReleaseToggles.map(releaseToggle => ({
      key: releaseToggle,
      value: releaseToggle,
      text: releaseToggle,
    })),
    'key'
  );

  const [releaseToggleOptions, setReleaseToggleOptions] = useState(defaultOptions);
  const [loading, setLoading] = useState(false);

  const handleAddition = useCallback(
    (_e, { value: releaseToggle }) => {
      setReleaseToggleOptions(releaseToggleOptions =>
        uniqBy(
          [
            ...releaseToggleOptions,
            { key: releaseToggle, text: releaseToggle, value: releaseToggle },
          ],
          'key'
        )
      );
    },
    [setReleaseToggleOptions]
  );

  const handleChange = useCallback(
    async (_e, { value: releaseToggles }) => {
      const type = releaseToggles.length > currentReleaseToggles.length ? 'add' : 'delete';
      const [releaseToggle] = difference(releaseToggles, currentReleaseToggles);

      setLoading(true);
      setCurrentReleaseToggles(releaseToggles);

      try {
        await onUpdate({ releaseToggle, type });
        addToast(`Release toggle "${releaseToggle}" ${type === 'add' ? 'added' : 'removed'}`, {
          appearance: 'success',
          autoDismiss: true,
        });
      } catch (e) {
        // reset the current release toggles when we error so the user isn't
        // shown an invalid state
        setCurrentReleaseToggles(currentReleaseToggles);
        addToast(
          `${
            type === 'add' ? 'Adding' : 'Removing'
          } release toggle "${releaseToggle}" failed: ${getErrorMessage(e)}`,
          {
            appearance: 'error',
            autoDismiss: true,
          }
        );
      } finally {
        setLoading(false);
      }
    },
    [addToast, currentReleaseToggles, onUpdate, setCurrentReleaseToggles, setLoading]
  );

  return (
    <Container>
      <Header as="h3">Release Toggles</Header>
      <Dropdown
        allowAdditions={true}
        fluid={true}
        loading={loading}
        multiple={true}
        onAddItem={handleAddition}
        onChange={handleChange}
        options={releaseToggleOptions}
        placeholder="Release Toggles"
        search={true}
        selection={true}
        value={currentReleaseToggles}
      />
      <Note>
        Release toggles must be in one of the following formats:
        <Code>{'rtv1:<feature>'}</Code> or
        <Code>{'rtv1:<scopes>:<feature>'}</Code> Multiple comma-separated scopes are allowed.
      </Note>
    </Container>
  );
}

export default ReleaseToggles;
