import React, { useCallback, useEffect, useState } from 'react';
import { Table, TableHeaderCell } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetUsersByOrganizationIdLazyQuery } from 'generated/graphql';
import OrganizationUserRow from './OrganizationUserRow';
import { formatOrganizationUser, sortOrganizationUsers } from './utils';
import { OrganizationUserExtended } from './types';
import { useClientTableSort } from 'lib/table';

// only retrieve 10 user records at a time to reduce the load on assets
// service per request
const limit = 10;

interface Props {
  organizationId: string;
}

function OrganizationUsersList({ organizationId }: Props) {
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [offset, setOffset] = useState<string | null | undefined>();
  const { addRecords, sortColumn, sorted, state } = useClientTableSort<OrganizationUserExtended>({
    sorter: sortOrganizationUsers,
  });
  const { records: users } = state;

  const [loadUsers, { error, loading }] = useGetUsersByOrganizationIdLazyQuery({
    errorPolicy: 'all',
    onCompleted: data => {
      const newUsers =
        data?.usersByOrganizationId?.nodes
          .map(formatOrganizationUser(organizationId))
          .filter(user => user.enabled) ?? [];

      addRecords(newUsers, true);

      const pageInfo = data?.usersByOrganizationId?.pageInfo;
      setLoadMore(Boolean(pageInfo?.endCursor));
      setOffset(pageInfo?.endCursor);
    },
  });

  const load = useCallback(() => {
    if (!loading) {
      loadUsers({ variables: { organizationId, limit, offset } });
    }
  }, [organizationId, loading, loadUsers, offset]);

  useEffect(() => {
    load();
  }, []);

  return (
    <InfiniteScroll dataLength={users.length} hasMore={loadMore} loader={null} next={load}>
      <Table basic="very" compact={true} sortable={true}>
        <Table.Header>
          <Table.Row>
            <TableHeaderCell onClick={() => sortColumn('name')} sorted={sorted('name')}>
              Name
            </TableHeaderCell>
            <TableHeaderCell onClick={() => sortColumn('email')} sorted={sorted('email')}>
              Email
            </TableHeaderCell>
            <TableHeaderCell onClick={() => sortColumn('roleId')} sorted={sorted('roleId')}>
              Role
            </TableHeaderCell>
            <TableHeaderCell>Locations</TableHeaderCell>
            <TableHeaderCell>Collaborator</TableHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {error && (
            <Table.Row>
              <Table.Cell collapsing={true} colSpan={5}>
                {error}
              </Table.Cell>
            </Table.Row>
          )}
          {users.map(user => {
            return <OrganizationUserRow key={user.id} user={user} />;
          })}
          {loading && (
            <Table.Row>
              <Table.Cell collapsing={true} colSpan={5}>
                Loading...
              </Table.Cell>
            </Table.Row>
          )}
          {!loading && !loadMore && users.length === 0 && (
            <Table.Row>
              <Table.Cell collapsing={true} colSpan={5}>
                This organization has no users.
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </InfiniteScroll>
  );
}

export default OrganizationUsersList;
