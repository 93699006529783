import React, { useState, useCallback } from 'react';
import { uniqBy, snakeCase } from 'lodash';
import { Dropdown, Header } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from '@apollo/client';

import getErrorMessage from '../lib/getErrorMessage';

const FEATURES = [
  'CAMPAIGNS',
  'COLLABORATORS',
  'DAMAGE_CONFIRM',
  'MEDIA_SERVICE',
  'SISENSE',
  'WORK_LOGS',
  'WORK_ORDERS',
  'ALERT_MANAGER',
];

export default function Features({ id, features = [], query }) {
  const { addToast } = useToasts();
  const [currentFeatures, setCurrentFeatures] = useState(features || []);

  const defaultFeatureOptions = uniqBy(
    FEATURES.concat(features).map(feature => ({
      key: feature,
      value: feature,
      text: feature,
    })),
    'key'
  );

  const [featureOptions, setFeatureOptions] = useState(defaultFeatureOptions);

  const [setFeatures, { loading: setFeaturesUpdating }] = useMutation(query);

  const handleAddition = useCallback((e, { value }) => {
    const feature = snakeCase(value).toUpperCase();
    setFeatureOptions(
      uniqBy([...featureOptions, { key: feature, text: feature, value: feature }], 'key')
    );
  });

  const handleChange = useCallback(async (e, { value }) => {
    const featuresToSet = value.map(val => snakeCase(val).toUpperCase());
    setCurrentFeatures(featuresToSet);

    try {
      await setFeatures({
        variables: {
          id,
          features: featuresToSet,
        },
      });
      addToast('Features Updated', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      console.error(err);
      addToast('Updating features failed: ' + getErrorMessage(err), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  });

  return (
    <div style={{ margin: '0 0 1em' }}>
      <Header as="h3">Features</Header>
      <Dropdown
        fluid
        multiple
        selection
        search
        allowAdditions
        placeholder="Features"
        onAddItem={handleAddition}
        onChange={handleChange}
        options={featureOptions}
        value={currentFeatures}
        loading={setFeaturesUpdating}
      />
    </div>
  );
}
