import React, { useCallback, useState } from 'react';
import { Checkbox, Form, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import getErrorMessage from '../../lib/getErrorMessage';
import UPDATE_MFA from './queries/updateMFAForOrganization.gql';

const Container = styled.div`
  margin: 1em 0;
`;

const Note = styled.div`
  font-style: italic;
  margin: 0.3em 0 0.6em 1.8em;
`;

function MultifactorAuthentication({ organization }) {
  const { id } = organization;
  const { addToast } = useToasts();
  const [mfa, setMfa] = useState(organization.mfa ?? { enabled: false, rememberDevice: true });
  const [updateMfa, { loading }] = useMutation(UPDATE_MFA);

  const handleChange = useCallback(
    async ({ item, ...mfa }) => {
      setMfa(mfa);
      try {
        await updateMfa({
          variables: {
            input: {
              id,
              mfa,
            },
          },
        });
        if (item === 'enabled') {
          addToast(`MFA ${mfa.enabled ? 'enabled' : 'disabled'}`, {
            appearance: 'success',
            autoDismiss: true,
          });
        } else {
          addToast(`MFA Remember Device ${mfa.rememberDevice ? 'enabled' : 'disabled'}`, {
            appearance: 'success',
            autoDismiss: true,
          });
        }
      } catch (err) {
        setMfa({ ...mfa, [item]: !mfa[item] });
        console.error(err);
        addToast('Updating MFA failed: ' + getErrorMessage(err), {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    },
    [addToast, id, setMfa, updateMfa]
  );

  return (
    <Container>
      <Header as="h3">Multifactor Authentication</Header>
      <Form.Field>
        <Checkbox
          checked={mfa.enabled}
          disabled={loading}
          label="Enable Multifactor Authentication (MFA)"
          name="enabled"
          onChange={(_, { checked }) =>
            handleChange({ enabled: checked, item: 'enabled', rememberDevice: mfa.rememberDevice })
          }
        />
        <Note>
          When enabled, users of the organization will be prompted for MFA when logging in.
        </Note>
      </Form.Field>

      <Form.Field>
        <Checkbox
          checked={mfa.rememberDevice}
          disabled={loading || !mfa.enabled}
          label="Enable MFA Remember Device"
          name="rememberDevice"
          onChange={(_, { checked }) =>
            handleChange({ enabled: mfa.enabled, item: 'rememberDevice', rememberDevice: checked })
          }
        />
        <Note>
          When enabled, users of the organization will have the option to remember the device for 30
          days to avoid being prompted with MFA within that period.
        </Note>
      </Form.Field>
    </Container>
  );
}

export default MultifactorAuthentication;
