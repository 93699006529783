import React from 'react';
import styled from 'styled-components';
import { Header } from 'semantic-ui-react';
import OrganizationNameModal from './OrganizationNameModal';

const SplitContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export default function OrganizationHeader({ id, name }) {
  return (
    <SplitContainer>
      <Header as="h1">{name}</Header>
      <OrganizationNameModal currentName={name} id={id} />
    </SplitContainer>
  );
}
