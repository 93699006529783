/*
this cursed file brought to you by:
⠀⠀⣿⣿⡆⠀⠀⢸⣷⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⣿⡇⠀⠀⣾⣿⡆
⠀⠀⣿⣿⡇⠀⠀⢸⣿⢰⣿⡆⠀⣾⣿⡆⠀⣾⣷ ⣿⣿⡇⠀⠀⣿⣿⡇
⠀⠀⣿⣿⡇⠀⠀⢸⣿⠘⣿⣿⣤⣿⣿⣿⣤⣿⡇⢻⣿⡇⠀⠀⣿⣿⡇
⠀⠀⣿⣿⡇⠀⠀⢸⡿⠀⢹⣿⣿⣿⣿⣿⣿⣿⠁⢸⣿⣇⠀⢀⣿⣿⠇
⠀⠀⠙⢿⣷⣶⣶⡿⠁⠀⠈⣿⣿⠟⠀⣿⣿⠇⠀⠈⠻⣿⣶⣾⡿⠋
*/

import React from 'react';
import styled from 'styled-components';
import { Link as WinkyLinky } from 'react-router-dom';
import { Button, Header as Headew, Segment } from 'semantic-ui-react';

const twue = true;

const Uwu = styled.h1`
  && {
    margin: 0.5em;
  }
`;

const EwwowMwessage = styled.p`
  text-align: center;
`;

const Bwutton = ({ pwimawwy, children: chiwdwen, ...pwops }) => (
  <Button primary={pwimawwy} {...pwops}>
    {chiwdwen}
  </Button>
);

const Seggyment = ({ pwacehowder, children: chiwdwen, ...pwops }) => (
  <Segment placeholder={pwacehowder} {...pwops}>
    {chiwdwen}
  </Segment>
);

const NotFoumd = () => {
  return (
    <Seggyment pwacehowder={twue}>
      <Headew icon>
        <Uwu>(●´ω｀●)</Uwu>
        oopsie woopsie!
      </Headew>
      <EwwowMwessage>
        uwu we made a fucky wucky! a wittle fucko boingo! the code monkeys at our headquarters are
        working VEWY HAWD to fix this!
      </EwwowMwessage>
      <Bwutton pwimawwy={twue} as={WinkyLinky} to="/">
        take me home uwu
      </Bwutton>
    </Seggyment>
  );
};

export default NotFoumd;
