import React, { useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { useQuery, useMutation, DocumentNode } from '@apollo/client';
import getErrorMessage from '../../../lib/getErrorMessage';

const noAccessRole = 'role-no-access';

export interface UserRole {
  name: string;
  isDeprecated?: boolean;
}

export interface SharedUserRoleDropdownProps {
  getUserRoles: (data: any) => UserRole[];
  getUserRolesDocument: DocumentNode;
  isBaseOrganization?: boolean;
  organizationId: string;
  roleId: string | null | undefined;
  setUserRoleDocument: DocumentNode;
  title: string;
  userId: string;
  disableNoAccess?: boolean;
}

export default function SharedUserRoleDropdown({
  getUserRoles,
  getUserRolesDocument,
  isBaseOrganization,
  organizationId,
  roleId,
  setUserRoleDocument,
  title,
  userId,
  disableNoAccess,
}: SharedUserRoleDropdownProps) {
  const { addToast } = useToasts();
  const [userRole, setUserRole] = useState<string>(roleId || noAccessRole);

  const { loading: userRolesLoading, data } = useQuery(getUserRolesDocument);
  const userRoles = getUserRoles(data);

  const [setUserRoleRemote, { loading: setUserRolesLoading }] = useMutation(setUserRoleDocument, {
    refetchQueries: ['userByEmail'],
  });

  const handleChange = async (
    _e: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps
  ) => {
    setUserRole(String(value) || noAccessRole);

    try {
      await setUserRoleRemote({
        variables: {
          input: {
            userId,
            // null indicates that we're unsetting the role
            roleId: value || null,
            organizationId,
          },
        },
      });

      addToast(`${title} updated!`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err: any) {
      console.error(err);
      // revert the user role when it errors
      setUserRole(userRole);
      addToast('There was a problem updating the user role: ' + getErrorMessage(err), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const options = useMemo(() => {
    const options = userRoles.map(({ name, isDeprecated }) => {
      return {
        key: name,
        value: name,
        text: isDeprecated ? `${name} [DEPRECATED]` : name,
      };
    });

    if (!disableNoAccess && (!userRole || userRole === noAccessRole)) {
      // `role-no-access` isn't explicitly returned from "get user roles", so
      // we need to add it to the beginning of the list of options to act as a
      // placeholder to indicate the user has no access
      options.unshift({ key: noAccessRole, value: noAccessRole, text: 'No Access' });
    }

    return options;
  }, [userRoles, userRole]);

  return (
    <div>
      <b>{title}</b>
      <Dropdown
        clearable={Boolean(
          !disableNoAccess && userRole && userRole !== noAccessRole && !isBaseOrganization
        )}
        fluid={true}
        loading={userRolesLoading || setUserRolesLoading}
        onChange={handleChange}
        options={options}
        placeholder="Role"
        search={true}
        selection={true}
        selectOnBlur={false}
        value={userRole}
      />
    </div>
  );
}
