import React, { useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { Button, Form, Header, Icon, Confirm } from 'semantic-ui-react';

import UPLOAD_LOGO from './queries/uploadLogo.gql';
import DELETE_LOGO from './queries/deleteLogo.gql';
import GET_ORGANIZATION_LOGO from './queries/getOrganizationLogo.gql';
import getErrorMessage from '../../lib/getErrorMessage';

export default function LogoUploader({ id }) {
  const [file, setFile] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { addToast } = useToasts();

  const [uploadLogo, { loading: uploading }] = useMutation(UPLOAD_LOGO);
  const [deleteLogo, { loading: deleting }] = useMutation(DELETE_LOGO);
  const [getLogoLink, { called, loading, data }] = useLazyQuery(GET_ORGANIZATION_LOGO, {
    fetchPolicy: 'network-only',
    variables: { id },
    onCompleted: () => {
      handleDownload();
    },
  });

  const handleChange = e => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      await uploadLogo({
        variables: {
          id,
          file,
        },
      });
      addToast('Upload Successful', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      console.error(err);
      addToast('Upload failed: ' + getErrorMessage(err), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const handleDownload = () => {
    try {
      const { logoLink } = data?.organization;
      if (!logoLink) {
        addToast('Could not find a Logo image for this Organization', {
          appearance: 'warning',
          autoDismiss: true,
        });
      } else {
        // Is there a better way to do this?
        const a = document.createElement('a');
        a.href = logoLink;
        a.setAttribute('download', 'true');
        document.body.appendChild(a);
        a.click();
        a.parentNode.removeChild(a);
      }
    } catch (err) {
      console.error(err);
      addToast('Unable to download image: ' + getErrorMessage(err), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const handleDelete = async () => {
    if (!isDeleting) return; // sanity check

    setIsDeleting(false);
    try {
      await deleteLogo({ variables: { id } });
      addToast('Delete Successful', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      addToast('Delete failed: ' + getErrorMessage(err), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const isWorking = (called && loading) || uploading || deleting;
  return (
    <div style={{ margin: '0 0 1em' }}>
      <Header as="h3">Report Logo</Header>
      <Form onSubmit={handleUpload}>
        <Form.Input
          required
          action="Upload"
          type="file"
          width={6}
          accept={'.png'}
          onChange={handleChange}
          disabled={isWorking}
        />
      </Form>
      <div style={{ margin: '1em 0 0' }}>
        <Button
          basic
          icon
          size="tiny"
          color="blue"
          labelPosition="left"
          loading={isWorking}
          onClick={() => getLogoLink()}
        >
          <Icon name="download" /> Download
        </Button>
        <Button
          basic
          icon
          size="tiny"
          color="red"
          labelPosition="left"
          loading={isWorking}
          onClick={() => setIsDeleting(true)}
        >
          <Icon name="trash" /> Remove
        </Button>
        <Confirm open={isDeleting} onCancel={() => setIsDeleting(false)} onConfirm={handleDelete} />
      </div>
    </div>
  );
}
