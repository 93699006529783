type StorageKey = 'recent-orgs' | 'recent-users';

/**
 * These storage key names should be JSON-encoded and JSON-decoded when adding
 * to and retrieving from storage, respectively.
 */
const jsonEncodedItems: StorageKey[] = ['recent-orgs', 'recent-users'];

/**
 * Retrieve an item from storage using the supplied key. When `parse` is true
 * and the key is parse of the available jsonEncodedItems, it will automatically
 * parse the storage value into an object (or whatever value was supplied).
 *
 * @param {StorageKey} key The storage key name
 * @param {boolean} parse When true, parse the data before returning
 */
export function getItem<T = any>(key: StorageKey, parse: boolean = true): T | null {
  try {
    const item = localStorage.getItem(key);
    if (item && (parse || jsonEncodedItems.includes(key))) {
      return JSON.parse(item);
    }
    return item as T;
  } catch (e: any) {
    console.error(`Unable to retrieve item from storage: ${key}`, e.message);
    return null;
  }
}

/**
 * Set an item into storage
 *
 * @param {StorageKey} key The storage key name
 * @param {any} value The data to add to storage at the key provided
 * @param {boolean} stringify When true, stringify the data before adding to storage
 */
export function setItem(key: StorageKey, value: any, stringify: boolean = true): void {
  try {
    const stringified =
      stringify || (jsonEncodedItems.includes(key) && typeof value !== 'string')
        ? JSON.stringify(value)
        : String(value);
    localStorage.setItem(key, stringified);
  } catch (e: any) {
    console.error(`Unable to add item to storage: ${key}`, e.message);
  }
}

/**
 * Remove an item from storage
 *
 * @param {StorageKey} key The storage key name
 */
export function removeItem(key: StorageKey) {
  try {
    localStorage.removeItem(key);
  } catch (e: any) {
    console.error(`Unable to remove item from storage: ${key}`, e.message);
  }
}

/**
 * Remove multiple items from storage
 *
 * @param {StorageKey[]} keys The storage key names
 */
export function removeItems(keys: StorageKey[]) {
  if (Array.isArray(keys)) {
    keys.forEach(removeItem);
  }
}

/**
 * Retrieve an item from storage, and then delete it from storage
 *
 * @param {StorageKey} key The storage key name
 * @param {boolean} parse When true, parse the data after retrieving from storage
 * @returns any
 */
export function pluck(key: StorageKey, parse = false): any {
  const item = getItem(key, parse);
  removeItem(key);
  return item;
}

/**
 * Remove all the items from storage and sessionStorage
 */
export function clear() {
  try {
    localStorage.clear();
    sessionStorage.clear();
  } catch (e: any) {
    console.error('Unable to clear storage');
  }
}
