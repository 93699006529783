import { gql, useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { usePasswordToast } from '../hooks/usePasswordToast';
import getErrorMessage from '../../lib/getErrorMessage';

export default function SetUserPassword({ id }) {
  const { addToast } = useToasts();
  const { addPasswordToast } = usePasswordToast();

  const SET_USER_PASSWORD = gql`
    mutation setUserPassword($id: ID!) {
      setUserPassword(id: $id)
    }
  `;

  const [setUserPassword, { loading, data }] = useMutation(SET_USER_PASSWORD);

  useEffect(() => {
    if (data) {
      addPasswordToast(data.setUserPassword);
    }
  }, [data]);

  const handlePasswordReset = async () => {
    try {
      await setUserPassword({ variables: { id } });
    } catch (err) {
      addToast('There was an error setting the password: ' + getErrorMessage(err), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <Popup
      content="A password reset email will NOT be sent. The new password will be shown here."
      trigger={
        <Button compact basic negative loading={loading} onClick={handlePasswordReset}>
          Set Password Manually
        </Button>
      }
    />
  );
}
