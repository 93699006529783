import { gql, useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import getErrorMessage from '../../lib/getErrorMessage';

const CREATE_ORGANIZATION = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
    }
  }
`;

export default function CreateOrganizationForm() {
  const history = useHistory();
  const { addToast } = useToasts();
  const [form, setForm] = useState({
    name: '',
    slug: '',
    sfId: '',
    mfa: {
      enabled: false,
      rememberDevice: false,
    },
  });

  const [createOrganization, { loading, data }] = useMutation(CREATE_ORGANIZATION);

  useEffect(() => {
    if (data) {
      history.push('/organizations/' + data.createOrganization.id);
    }
  }, [data]);

  const handleSubmit = async () => {
    try {
      await createOrganization({
        variables: {
          input: {
            ...form,
            mfa: form.mfa.enabled ? { ...form.mfa } : undefined,
          },
        },
      });
    } catch (err) {
      console.error(err);
      addToast('There was a problem creating this organization: ' + getErrorMessage(err), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const handleChange = (e, { checked, name, value }) => {
    if (name.startsWith('mfa')) {
      setForm({
        ...form,
        mfa: {
          ...form.mfa,
          [name.replace('mfa.', '')]: checked,
        },
      });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  return (
    <div>
      <h3> Create a Organization </h3>
      <Form loading={loading} onSubmit={handleSubmit}>
        <Form.Input
          fluid={true}
          required={true}
          name="name"
          value={form.name}
          label="Organization Name"
          onChange={handleChange}
          placeholder="Arcola Energy"
        />
        <Form.Input
          fluid={true}
          required={true}
          name="slug"
          value={form.slug}
          label="Organization Slug"
          onChange={handleChange}
          placeholder="arcola-energy"
        />
        <Form.Input
          fluid={true}
          name="sfId"
          type="number"
          value={form.sfId}
          label="Salesforce ID"
          onChange={handleChange}
          placeholder="Salesforce ID"
        />
        <Form.Field>
          <Checkbox
            checked={form.mfa.enabled}
            label="Enable Multifactor Authentication (MFA)"
            name="mfa.enabled"
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            checked={form.mfa.rememberDevice}
            disabled={!form.mfa.enabled}
            label="MFA Remember Device"
            name="mfa.rememberDevice"
            onChange={handleChange}
          />
        </Form.Field>
        <Button type="submit">Create</Button>
      </Form>
    </div>
  );
}
