import React, { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import GET_ORGANIZATION_BY_ID from './queries/getOrganizationById.gql';

import OrganizationUsersList from './OrganizationUsersList';
import OrganizationLocationsList from './OrganizationLocationsList';
import LogoUploader from './LogoUploader';

import { Container, Loader, Menu, Segment } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import Features from '../Features';
import SET_FEATURES from './queries/setFeatures.gql';
import MultifactorAuthentication from './MultifactorAuthentication';
import OrganizationReleaseToggles from './OrganizationReleaseToggles';
import OrganizationHeader from './OrganizationHeader';
import OrganizationSalesforceID from './OrganizationSalesforceID';
import { useRecentOrgs } from './utils';

export default function OrganizationDetail({ match }) {
  const [activeTab, setActiveTab] = useState('locations');
  const { loading, data } = useQuery(GET_ORGANIZATION_BY_ID, {
    fetchPolicy: 'cache-and-network',
    variables: { id: match.params.id },
  });

  const handleTabChange = useCallback((e, { name }) => {
    setActiveTab(name);
  });

  const organization = data?.organization;

  useRecentOrgs(organization);

  if (loading && !data) return <Loader active />;

  if (!organization) {
    return <Redirect to="/organizations" />;
  }

  return (
    <Container>
      <OrganizationHeader id={organization.id} name={organization.name} />
      <LogoUploader id={organization.id} />
      <Features id={organization.id} features={organization.features} query={SET_FEATURES} />
      <OrganizationReleaseToggles organization={organization} />
      <MultifactorAuthentication organization={organization} />
      <OrganizationSalesforceID organization={organization} />
      <Menu attached="top" tabular>
        <Menu.Item name="locations" active={activeTab === 'locations'} onClick={handleTabChange} />
        <Menu.Item name="users" active={activeTab === 'users'} onClick={handleTabChange} />
      </Menu>
      <Segment attached="bottom" style={{ minHeight: '5em' }}>
        {activeTab === 'locations' ? (
          <OrganizationLocationsList id={organization.id} />
        ) : (
          <OrganizationUsersList organizationId={organization.id} />
        )}
      </Segment>
    </Container>
  );
}
