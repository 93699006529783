import React from 'react';
import OrganizationCard from '../Organizations/OrganizationCard';

export default function UserOrganizations({ user, organizations }) {
  const orgsCopy = [...organizations];
  const defaultOrgIndex = orgsCopy.findIndex(o => o.isBaseOrganization === true);
  // pull default to keep it at the top
  const defaultOrg = orgsCopy[defaultOrgIndex];
  orgsCopy.splice(defaultOrgIndex, 1);
  // client-side sort due to dynamo structure and resolver not condusive to server-side
  const sortedOrgs = orgsCopy.sort((a, b) => a.name.localeCompare(b.name));
  const orgs = [defaultOrg, ...sortedOrgs];
  return (
    <div>
      {orgs.map(o => (
        <OrganizationCard key={o.id} user={user} organization={o} />
      ))}
    </div>
  );
}
