import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import {
  Button,
  Form,
  Grid,
  GridColumn,
  Header,
  HeaderContent,
  HeaderSubheader,
  Icon,
  List,
  ListContent,
  ListHeader,
  ListItem,
} from 'semantic-ui-react';
import { useHistory, Link } from 'react-router-dom';
import RecentUsers from './RecentUsers';
import { validate as validateUuid } from 'uuid';
import RecentOrgs from 'components/Organizations/RecentOrgs';
import { useGetUserEmailsLazyQuery } from 'generated/graphql';

const Container = styled.div`
  padding: 3rem 2rem 0 2rem;
`;

const RecentsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 0;
  flex-wrap: wrap;
  gap: 20px;
`;

const Code = styled.code`
  background: #ececec;
  padding: 0 3px;
`;

const MIN_CHARACTERS = 3;

export default function UserSearchBar() {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Array<string>>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const isUUID = useMemo(() => validateUuid(debouncedSearchTerm), [debouncedSearchTerm]);

  const [getUserEmails] = useGetUserEmailsLazyQuery({
    variables: { filter: debouncedSearchTerm },
    onCompleted(data) {
      if (data.userEmails?.length) {
        setSearchResults(data.userEmails);
      } else {
        setSearchResults([]);
      }
    },
  });

  useEffect(() => {
    if (searchTerm.length >= MIN_CHARACTERS) {
      const handler = setTimeout(() => {
        setDebouncedSearchTerm(searchTerm);
      }, 300);

      return () => {
        clearTimeout(handler);
      };
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= MIN_CHARACTERS) {
      getUserEmails();
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchTerm, getUserEmails]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        const nextIdx =
          selectedIndex === null || selectedIndex === searchResults.length - 1
            ? 0
            : selectedIndex + 1;
        setSelectedIndex(nextIdx);
      } else if (e.key === 'ArrowUp') {
        const nextIdx =
          selectedIndex === null || selectedIndex === 0
            ? searchResults.length - 1
            : selectedIndex - 1;
        setSelectedIndex(nextIdx);
      } else if (e.shiftKey && e.key === 'Enter' && searchResults.length > 0) {
        history.push(`/users/${searchResults[0]}`);
      } else if (e.key === 'Enter' && selectedIndex !== null) {
        history.push(`/users/${searchResults[selectedIndex]}`);
      }
    },
    [selectedIndex, searchResults]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Container>
      <Grid>
        <GridColumn width={12}>
          <Form.Input
            fluid={true}
            name="search"
            onChange={(_e, { value }) => setSearchTerm(value?.toLowerCase())}
            placeholder="Search by email or user ID..."
            style={{ flex: '1 0 100%' }}
            value={searchTerm}
          />
        </GridColumn>
        <GridColumn width={4}>
          <Button
            fluid={true}
            secondary
            as={Link}
            to={`/users/create/${isUUID ? '' : debouncedSearchTerm}`}
          >
            <Icon name="add user" />
            Create New User
          </Button>
        </GridColumn>
        <GridColumn width={10}>
          <Header as="h3">
            <HeaderContent>
              Search Results
              <HeaderSubheader>
                Tip: Use <Code>shift+enter</Code> to go to first result or arrows to navigate
              </HeaderSubheader>
            </HeaderContent>
          </Header>
          <List
            selection={searchResults.length !== 0}
            verticalAlign="middle"
            style={{ height: 400, overflowY: 'auto', overflowX: 'hidden' }}
          >
            {searchResults.length > 0 &&
              searchResults.map((r, idx) => (
                <ListItem
                  key={idx}
                  onClick={() => history.push(`/users/${r}`)}
                  active={idx === selectedIndex}
                >
                  <ListContent>
                    <ListHeader style={{ color: '#4183c4' }}>{r}</ListHeader>
                  </ListContent>
                </ListItem>
              ))}
            {searchResults.length === 0 && (
              <ListItem>
                <ListContent>
                  <ListHeader>No results</ListHeader>
                </ListContent>
              </ListItem>
            )}
          </List>
        </GridColumn>
        <GridColumn width={6}>
          <RecentsContainer>
            <RecentUsers />
            <RecentOrgs />
          </RecentsContainer>
        </GridColumn>
      </Grid>
    </Container>
  );
}
