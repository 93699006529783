import React, { useState, useCallback } from 'react';
import { Grid, Dropdown } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { useQuery, useMutation } from '@apollo/client';

import SET_USER_GROUPS from './queries/setUserGroups.gql';
import getErrorMessage from '../../lib/getErrorMessage';
import GET_USER_GROUPS from './queries/getUserGroups.gql';

export default function UserGroupsDropdown({ id, groups }) {
  const { addToast } = useToasts();
  const [currentGroups, setCurrentGroups] = useState(groups.map(({ name }) => name));

  const { data: { userGroups = [] } = {} } = useQuery(GET_USER_GROUPS);
  const [setUserGroupsRemote, { loading: userGroupsUpdating }] = useMutation(SET_USER_GROUPS, {
    refetchQueries: ['userByEmail'],
  });

  const handleChange = useCallback(async (e, { value }) => {
    setCurrentGroups(value);

    try {
      await setUserGroupsRemote({
        variables: {
          input: {
            groups: value,
            id,
          },
        },
      });
      addToast('Groups Updated!', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      console.error(err);
      // Restore previous values
      setCurrentGroups(currentGroups);
      addToast('There was a problem updating groups: ' + getErrorMessage(err), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  });

  const groupOptions = userGroups
    ? userGroups.map(({ name }) => {
        return { key: name, value: name, text: name };
      })
    : [];

  return (
    <div style={{ marginTop: '5px' }}>
      <h4>User Groups</h4>
      <Grid>
        <Grid.Column width={16}>
          <Dropdown
            fluid
            search
            multiple
            selection
            header="Groups"
            placeholder="Groups"
            onChange={handleChange}
            options={groupOptions}
            value={currentGroups}
            loading={userGroupsUpdating}
          />
        </Grid.Column>
      </Grid>
    </div>
  );
}
