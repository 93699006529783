import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Checkbox, Popup } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';

import SET_CAN_ACCESS_IN_PROGRESS from './queries/setCanAccessInProgress.gql';

export default function CanAccessInProgress({
  userId,
  organizationId,
  canAccessInProgress: canAccessPropState,
}) {
  const { addToast } = useToasts();
  const [remoteSetAccessInProgress] = useMutation(SET_CAN_ACCESS_IN_PROGRESS);
  const [canAccessInProgress, setAccessInProgress] = useState(canAccessPropState);

  const handleCanAccessInProgress = async () => {
    try {
      await remoteSetAccessInProgress({
        variables: {
          input: {
            id: userId,
            canAccessInProgress: !canAccessInProgress,
            organizationId,
          },
        },
      });

      addToast('access updated!', { appearance: 'success', autoDismiss: true });
      setAccessInProgress(!canAccessInProgress);
    } catch (err) {
      addToast('can access in progress data update failed: ' + err.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <Popup
      content="Allows the user to view data that has not been released from QC"
      trigger={
        <Checkbox
          label="Can access unreleased data"
          checked={canAccessInProgress}
          onChange={() => handleCanAccessInProgress()}
        />
      }
    />
  );
}
