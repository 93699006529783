import React, { useState, useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Header, Form, Message } from 'semantic-ui-react';
import SET_ORGANIZATION_NAME from './queries/setOrganizationName.gql';

export default function OrganizationNameModal({ id, currentName }) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(currentName);
  const [formError, setFormError] = useState(null);
  const [setNameMutation, { data, loading, error }] = useMutation(SET_ORGANIZATION_NAME);

  const handleChange = (e, { value }) => {
    setName(value);
    setFormError(null);
  };

  const handleSubmit = useCallback(async () => {
    if (name === currentName) {
      setFormError({ content: 'Name not changed' });
      return;
    }
    const { data, error } = await setNameMutation({
      variables: {
        input: { id, name },
      },
    });
    if (!error) {
      setOpen(false);
      setName(data.setOrganizationName.organization.name);
    }
  }, [name, currentName]);

  const handleClose = useCallback(() => {
    setName(currentName);
    setOpen(false);
  }, [currentName]);

  useEffect(() => {
    if (data) {
      history.push('/organizations/' + data.setOrganizationName.organization.id);
    }
  }, [data]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={<Button>Edit Name</Button>}
    >
      <Header>Edit Name</Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <Form.Input
              label="New Name"
              error={formError}
              required={true}
              placeholder={currentName}
              name="name"
              value={name}
              onChange={handleChange}
            />
          </Form.Field>
          {error && (
            <Message negative>
              <Message.Header>Error submitting name change</Message.Header>
              <p>{error.message}</p>
            </Message>
          )}
          <Button type="submit" loading={loading}>
            Submit
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
}
