import { useCallback, useEffect, useState } from 'react';
import { getItem, removeItem, setItem } from 'lib/storage';
import { uniqBy } from 'lodash';
import { OrganizationUser, OrganizationUserExtended } from './types';

interface RecentOrg {
  id: string;
  name: string;
}

export function useRecentOrgs(org?: RecentOrg | null) {
  const [recentOrgs, setRecentOrgs] = useState<RecentOrg[]>(getRecentOrgs());

  const deleteRecentOrg = useCallback((deleteId: string) => {
    const newOrgs = removeRecentOrg(deleteId);
    setRecentOrgs(newOrgs);
  }, []);

  const add = useCallback((org: RecentOrg) => {
    const newOrgs = addRecentOrg(org);
    setRecentOrgs(newOrgs);
  }, []);

  useEffect(() => {
    if (org?.id) {
      add(org);
    }
  }, [org]);

  return { addRecentOrg: add, deleteRecentOrg, recentOrgs };
}

function getRecentOrgs(): RecentOrg[] {
  return getItem<RecentOrg[]>('recent-orgs') ?? [];
}

function addRecentOrg(org: RecentOrg): RecentOrg[] {
  const recentOrgs = getRecentOrgs();
  const newOrgs = uniqBy([org, ...recentOrgs].filter(Boolean), 'id').slice(0, 10);

  setItem('recent-orgs', newOrgs);

  return newOrgs;
}

function removeRecentOrg(id: string): RecentOrg[] {
  const recentOrgs = getRecentOrgs();
  const newOrgs = recentOrgs.filter(({ id: anId }) => anId !== id);

  if (newOrgs.length === 0) {
    removeItem('recent-orgs');
  } else {
    setItem('recent-orgs', newOrgs);
  }

  return newOrgs;
}

export function formatOrganizationUser(currentOrganizationId: string) {
  return (user: OrganizationUser): OrganizationUserExtended => {
    const { organizations } = user;
    const currentOrganization = organizations?.find(
      o => o?.organizationId === currentOrganizationId
    );
    const baseOrganization = organizations?.find(o => o?.isBaseOrganization);

    return {
      ...user,
      baseOrganization,
      currentOrganization,
      locations: currentOrganization?.locations,
      roleId: currentOrganization?.roleId,
    };
  };
}

function sortOrganizationUsersBy(
  field: keyof Pick<OrganizationUserExtended, 'email' | 'firstName' | 'lastName' | 'id' | 'roleId'>,
  direction: 'ascending' | 'descending'
) {
  const opts: Intl.CollatorOptions = { sensitivity: 'base' };

  return (a: OrganizationUserExtended, b: OrganizationUserExtended) => {
    const ref = direction === 'ascending' ? a : b;
    const compare = direction === 'ascending' ? b : a;
    const initialCompare = ref[field]?.localeCompare(compare[field] ?? '', undefined, opts);

    if (!initialCompare) {
      // there was no difference for the initial comparison, so attempt to
      // fallback to a second comparison field
      if (field === 'lastName') {
        // when sorting by last name, sort by first name second
        return ref.firstName?.localeCompare(compare.firstName ?? '', undefined, opts) || 0;
      } else if (field === 'roleId') {
        // when sorting by roleId, sort by email second
        return ref.email?.localeCompare(compare?.email ?? '', undefined, opts) || 0;
      }
    }

    return initialCompare || 0;
  };
}

export function sortOrganizationUsers(
  column: string,
  users: OrganizationUserExtended[],
  direction: 'ascending' | 'descending'
) {
  if (column === 'name') return [...users].sort(sortOrganizationUsersBy('lastName', direction));
  if (column === 'email') return [...users].sort(sortOrganizationUsersBy('email', direction));
  if (column === 'roleId') return [...users].sort(sortOrganizationUsersBy('roleId', direction));
  return users;
}
