import React from 'react';
import SET_USER_ROLE from '../queries/setUserOrgRole.gql';
import GET_USER_ROLES from '../queries/getUserRoles.gql';
import SharedUserRoleDropdown, { SharedUserRoleDropdownProps } from './SharedUserRoleDropdown';

interface Props
  extends Pick<
    SharedUserRoleDropdownProps,
    'isBaseOrganization' | 'organizationId' | 'roleId' | 'userId'
  > {}

export default function UserRoleDropdown(props: Props) {
  return (
    <SharedUserRoleDropdown
      {...props}
      disableNoAccess
      getUserRoles={data => data?.userRoles ?? []}
      getUserRolesDocument={GET_USER_ROLES}
      setUserRoleDocument={SET_USER_ROLE}
      title="Horizon Role"
    />
  );
}
