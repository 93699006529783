import React, { useCallback, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from '@apollo/client';
import ADD_GLOBAL_RELEASE_TOGGLE from './queries/addGlobalReleaseToggle.gql';

function AddGlobalReleaseToggle({ onAddReleaseToggle }) {
  const { addToast } = useToasts();
  const [name, setName] = useState('');
  const [addGlobalReleaseToggle] = useMutation(ADD_GLOBAL_RELEASE_TOGGLE);

  const handleAddToggle = useCallback(
    async ({ name }) => {
      try {
        const response = await addGlobalReleaseToggle({
          variables: {
            input: {
              name,
            },
          },
        });

        setName('');

        if (onAddReleaseToggle) {
          await onAddReleaseToggle(response.data?.addGlobalReleaseToggle?.releaseToggle);
        }
      } catch (e) {
        addToast(`Error adding release toggle: ${e.message}`, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    },
    [addGlobalReleaseToggle, onAddReleaseToggle, setName]
  );

  return (
    <Form onSubmit={() => handleAddToggle({ name })}>
      <Form.Input
        action={{ icon: 'plus' }}
        fluid={true}
        onChange={(_, { value }) => setName(value)}
        placeholder="Add new release toggle (e.g. rtv1:uat:some_new_feature)"
        value={name}
      />
    </Form>
  );
}

export default AddGlobalReleaseToggle;
