import React from 'react';
import styled from 'styled-components';

import { UserNiaaRoleDropdown, UserRoleDropdown } from '../Users/UserRoleDropdowns';
import UserOrgExpirationSelector from '../Users/UserOrgExpirationSelector';
import CanAccessInProgress from '../Users/CanAccessInProgress';
import CanReceiveCDN from '../Users/CanReceiveCDN';
import UserLocationsDropdown from '../Users/UserLocationsDropdown';

import SET_BASE_ORGANIZATION from '../Users/queries/setBaseOrganization.gql';
import REMOVE_ORGANIZATION_PERMISSION from '../Users/queries/removeOrganizationPermission.gql';

import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { Grid, Icon, Button } from 'semantic-ui-react';
import { grey } from '../../lib/colors';
import { Link } from 'react-router-dom';

const Container = styled.div`
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0px 10px 0px;
  background: ${({ org = {} }) => (org.isBaseOrganization ? grey : 'none')};
  border: 1px solid ${grey};
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OrgTitle = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
`;

const OrgMfa = styled.span`
  font-size: 0.8em;
  font-style: italic;
  font-weight: normal;
  margin-left: 15px;
`;

export default function OrganizationCard({ user, organization }) {
  const { addToast } = useToasts();

  const [deleteOrgPermission, { loading }] = useMutation(REMOVE_ORGANIZATION_PERMISSION, {
    variables: {
      input: { userId: user.id, organizationId: organization.id },
    },
    refetchQueries: ['userByEmail'],
  });

  const [setBaseOrganization, { loading: settingBaseOrganization }] = useMutation(
    SET_BASE_ORGANIZATION,
    {
      variables: {
        id: user.id,
        organizationId: organization.id,
      },
      refetchQueries: ['userByEmail'],
    }
  );

  async function handleDeleteOrgPermission() {
    await deleteOrgPermission();
    addToast('Organization record removed', {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  async function handleSetBaseOrganization() {
    try {
      await setBaseOrganization();
      addToast('Set new base organization', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container org={organization}>
      <FlexContainer>
        <OrgTitle>
          <Link to={`/organizations/${organization.id}`}>{organization.name}</Link>
          {organization.isBaseOrganization && organization.mfa?.enabled && (
            <OrgMfa>MFA is enabled</OrgMfa>
          )}
        </OrgTitle>

        {!organization.isBaseOrganization ? (
          <div>
            <Button
              title="Set as base organization"
              loading={settingBaseOrganization}
              basic
              circular
              disabled={organization.isBaseOrganization}
              onClick={handleSetBaseOrganization}
              icon
              size="tiny"
              color="yellow"
            >
              <Icon name="star" />
            </Button>
            <Button
              loading={loading}
              basic
              circular
              disabled={organization.isBaseOrganization}
              onClick={handleDeleteOrgPermission}
              icon
              size="tiny"
              color="red"
            >
              <Icon name="trash" />
            </Button>
          </div>
        ) : (
          <div>Base Organization</div>
        )}
      </FlexContainer>
      <Grid>
        <Grid.Row>
          <Grid.Column key="locations" width={7}>
            <UserLocationsDropdown
              userId={user.id}
              organizationId={organization.id}
              locations={organization.locations}
            />
          </Grid.Column>
          <Grid.Column key="expires" width={4}>
            {!organization.isBaseOrganization && (
              <UserOrgExpirationSelector
                userId={user.id}
                organizationId={organization.id}
                disabled={organization.isBaseOrganization}
                expires={organization.expires}
              />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column key="role" width={6}>
            <UserRoleDropdown
              isBaseOrganization={organization.isBaseOrganization}
              organizationId={organization.id}
              roleId={organization.roleId}
              userId={user.id}
            />
          </Grid.Column>
          <Grid.Column key="niaaRole" width={6}>
            <UserNiaaRoleDropdown
              organizationId={organization.id}
              roleId={organization.niaaRoleId}
              userId={user.id}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid columns="equal">
        <Grid.Column>
          <CanAccessInProgress
            canAccessInProgress={Boolean(organization.canAccessInProgress)}
            userId={user.id}
            organizationId={organization.id}
          />
        </Grid.Column>
        <Grid.Column>
          <CanReceiveCDN
            canReceiveCDN={Boolean(organization.canReceiveCDN)}
            userId={user.id}
            organizationId={organization.id}
          />
        </Grid.Column>
      </Grid>
    </Container>
  );
}
